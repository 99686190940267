<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <v-form
          ref="profileSettingForm"
          @submit.prevent="saveProfileSetting"
          class="custom-form"
        >
          <v-card :elevation="1" color="blue lighten-5">
            <v-row no-gutters>
              <v-col cols="12">
                <v-toolbar color="#546E7A" dark :elevation="1" height="30px">
                  <v-toolbar-title class="subtitle-2"
                    >Profile Setting</v-toolbar-title
                  >
                </v-toolbar>
                <v-card-text class="py-2 mt-1">
                  <v-row dense justify="center">
                    <v-col cols="4">
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4" class="text-right pr-4">Name</v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[v => !!v]"
                            v-model.trim="profile.name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4" class="text-right pr-4">Username</v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            :rules="[v => !!v]"
                            v-model.trim="profile.username"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4" class="text-right pr-4">E-Mail</v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.trim="profile.email"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="4" class="text-right pr-4">Phone</v-col>
                        <v-col cols="8">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.trim="profile.phone"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="5">
                      <v-row no-gutters class="mb-1">
                        <v-col cols="5" class="text-right pr-4"
                          >Current Password</v-col
                        >
                        <v-col cols="7">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.trim="password.currentPassword"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="5" class="text-right pr-4"
                          >New Password</v-col
                        >
                        <v-col cols="7">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.trim="password.newPassword"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mb-1">
                        <v-col cols="5" class="text-right pr-4"
                          >Confirm Password</v-col
                        >
                        <v-col cols="7">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model.trim="password.confirmPassword"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="5"></v-col>
                        <v-col cols="7">
                          <v-row no-gutters>
                            <v-col cols="6" class="pr-1">
                              <v-btn
                                @click="resetForm"
                                height="30px"
                                dark
                                block
                                color="deep-orange"
                                >Clear</v-btn
                              >
                            </v-col>
                            <v-col cols="6">
                              <v-btn
                                type="submit"
                                :loading="waitingForSave"
                                height="30px"
                                dark
                                block
                                color="light-blue darken-2"
                                >Save</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProfileSetting",
  data: () => ({
    profile: {
      name: "",
      username: "",
      email: "",
      phone: ""
    },
    password: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    waitingForSave: false
  }),
  async created() {
    let userData = await this.$store.dispatch("user/getCurrentUser");
    if (userData.success)
      Object.keys(this.profile).map(k => (this.profile[k] = userData.user[k]));
  },
  methods: {
    async saveProfileSetting() {
      if (!this.$refs.profileSettingForm.validate()) return;
      if (this.password.newPassword && this.password.confirmPassword == "") {
        this.$store.dispatch("snackbar/error", "Confirm password is required");
        return;
      }
      if (this.password.newPassword != this.password.confirmPassword) {
        this.$store.dispatch(
          "snackbar/error",
          "New password & Confirm password didn't match"
        );
        return;
      }
      if (
        this.password.newPassword &&
        this.password.confirmPassword &&
        this.password.currentPassword == ""
      ) {
        this.$store.dispatch("snackbar/error", "Current password is required");
        return;
      }
      this.waitingForSave = true;
      let userData = { ...this.profile };
      userData.id = this.$store.getters["auth/userId"];
      if (this.password.confirmPassword != "") {
        userData.currentPassword = this.password.currentPassword;
        userData.password = this.password.confirmPassword;
      }
      let res = await this.$store.dispatch("user/saveUser", {
        url: "update-user",
        data: userData
      });
      if (res) {
        Object.keys(this.password).map(k => (this.password[k] = ""));
        this.$store.dispatch("user/getCurrentUser");
      }
      this.waitingForSave = false;
    },

    resetForm() {
      Object.keys(this.profile).map(k => (this.profile[k] = ""));
      Object.keys(this.password).map(k => (this.password[k] = ""));
      this.$refs.profileSettingForm.resetValidation();
    }
  }
};
</script>

<style lang="scss" scoped>
.v-icon.v-icon {
  font-size: 18px !important;
  top: -3px !important;
}
.v-icon.v-icon[data-v-1f38b4e5] {
  font-size: 14px !important;
  top: 0px !important;
}
.v-messages {
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 0px !important;
  max-height: 0px !important;
  min-width: 1px;
  position: relative;
}
</style>